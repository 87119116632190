import React from 'react';

import Pricing from '../modules/pages/pricing/Pricing';

const pricing = () => {
  return (
    <Pricing
      seo={{
        title: 'Planos',
        description: 'Nunca foi tão fácil entender seu cliente! Integre hoje e crie suas análises em minutos.',
        lang: 'pt',
      }}
    />
  );
};

export default pricing;
